<template>
  <div>
    <div class="container">
      <div class="header">     
        <!-- 文章来源 -->
        <div class="header-item">
          <el-radio-group v-model="articleQuery.isStaffProvide">
            <el-radio-button label="1+">全部</el-radio-button>
            <el-radio-button label="0">系统提供</el-radio-button>
            <el-radio-button label="1">员工提供</el-radio-button>
          </el-radio-group>
        </div>

        <!-- 文章状态 -->
        <div class="header-item" style="width: 100px">
          <el-select v-model="articleQuery.status">
            <el-option v-for="item in articleStatus" :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>

        <!-- 文章标题搜索框 -->
        <div class="header-item">
          <el-input
            clearable
            placeholder="请输入文章标题"
            suffix-icon="el-icon-search"
            v-model="articleQuery.title">
          </el-input>
        </div>

        <!-- 搜索和自定义添加文章按钮 -->
        <div class="header-item">
          <el-button @click="getArticleList()" type="primary" icon="el-icon-search">搜索</el-button>
        </div>
        <el-button 
          @click="retirectPage('')"
          type="primary" style="float: right; margin-left: 10px">自定义添加文章</el-button>
        <el-button 
          @click="pickContent"
          type="primary" style="float: right">采集文章</el-button>
      </div>

      <div class="header">
        <!-- 数据排序方式 -->
        <div class="header-item">
          <el-radio-group v-model="articleQuery.sortType">
            <el-radio-button :label="0">创建时间排序</el-radio-button>
            <el-radio-button :label="1">发布时间排序</el-radio-button>
          </el-radio-group>
        </div>

        <!-- 时间输入框 -->
        <div class="header-item">
          <el-date-picker
            v-model="condtionTime"
            type="datetimerange"
            :start-placeholder="articleQuery.sortType ? '发布开始时间' : '创建开始时间'"
            :end-placeholder="articleQuery.sortType ? '发布结束时间' : '创建结束时间'"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </div>
      </div>

      <el-table
        ref="multipleTable"
        :data="articleList"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-row-class-name="table-header-style"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
        @selection-change="handleSelectionChange">
        <!-- 选择框 -->
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- 标题 -->
        <el-table-column label="文章标题">
          <template slot-scope="scope">
            <p @click="retirectPage(scope.row)" class="table-title">{{ scope.row.title }}</p>
          </template>
        </el-table-column>
        <!-- 来源 -->
        <el-table-column prop="source" label="来源" width="50">
          <template slot-scope="scope">
            <p>{{ scope.row.flag ? '员工' : '系统' }}</p>
          </template>
        </el-table-column>
        <!-- 详细来源 -->
        <!-- <el-table-column prop="detailedSource" label="详细来源" width="150"></el-table-column> -->
        <!-- 文章状态 -->
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <p>{{ scope.row.status | articleStatus }}</p>
          </template>
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column label="创建时间" width="170">
          <template slot-scope="scope">
            <p>{{ scope.row.created_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
          </template>
        </el-table-column>
        <!-- 发布时间 -->
        <el-table-column label="发布时间" width="170">
          <template slot-scope="scope">
            <p>{{ scope.row.updated_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
          </template>
        </el-table-column>
        <!-- 阅读数量 -->
        <el-table-column label="阅读数量" width="80">
          <template slot-scope="scope">
            <p style="text-align: center">{{ scope.row.read_count || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 分享次数 -->
        <el-table-column label="分享次数" width="80">
          <template slot-scope="scope">
            <p style="text-align: center">{{ scope.row.share_count || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 点赞人数 -->
        <el-table-column label="点赞人数" width="80">
          <template slot-scope="scope">
            <p style="text-align: center">{{ scope.row.praise_count || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 发布、下架、删除 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == '2'"
              @click="updateStatus(scope.row, 'dismount')" type="text" size="small">下架</el-button>
            <el-button v-else
              @click="updateStatus(scope.row, 'release')" type="text" size="small">发布</el-button>
            <el-button  @click="updateStatus(scope.row, 'del')" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <!-- 批量操作按钮 -->
        <el-button 
          :disabled="!multipleSelection"
          @click="updateMultipleStatus('delete')">批量删除</el-button>
        <el-button
          :disabled="!multipleSelection"
          @click="updateMultipleStatus('release')">批量发布</el-button>
        <el-button 
          :disabled="!multipleSelection"
          @click="updateMultipleStatus('dismount')">批量下架</el-button>
        

        <!-- 分页 -->
        <div style="float: right">
          <el-pagination
            :background="true"
            @current-change="getArticleList"
            :current-page="articleQuery.pageIndex"
            :page-size="articleQuery.pageNumber"
            layout="total, prev, pager, next, jumper"
            :total="articleTotal">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      articleStatus: [ // 文章状态
        { value: "1+", label: '全部'}, 
        { value: "2", label: '已发布'},
        { value: "1", label: '未发布'}, 
        { value: "0", label: '下架'}, 
      ],
      articleQuery: { // 查询条件
        type: 0,
        title: '',
        status: "1+", // 0：下架；1：隐私 只有自己可见（未发布）；2：公开（已发布）
        sortType: 0, // 排序方式 0创建时间；1发布时间
        isStaffProvide: "1+",
        pageIndex: 1,
        pageNumber: 8,
        startTime: "",
        endTime: ""
      },
      articleTotal: 0, // 文章总数
      articleList: [], // 文章列表
      condtionTime: [], // 查询时间
      multipleSelection: "", // 用户当前选中的文章ID组
    }
  },
  created(){
    this.condtionTime = [this.$dayjs().subtract(1, 'year').format('YYYY-MM-DD'), this.$dayjs().add(1, 'day').format('YYYY-MM-DD')];
    this.getArticleList();
  },
  methods: {
    // 获取文章列表
    async getArticleList(pageIndex){
      this.articleQuery.startTime = this.condtionTime[0];
      this.articleQuery.endTime = this.condtionTime[1];
      console.log(this.articleQuery);
      this.articleQuery.pageIndex = pageIndex || 1;
      let res = await this.$ZGManager.getArticleList(this.articleQuery);
      if(res.status == 200 && res.data){
        this.articleList = res.data.list;
        this.articleTotal = res.data.total_rows;
      }
    },

    // 修改单个文章状态
    async updateStatus(item, type){
      let params = {};
      switch(type){
        case 'release': 
          params.status = "2";
          break;
        case 'dismount':
          params.status = "0";
          break;
        case 'del':
          params.status = "-1";
          break;
        default: status = item.status;
      }
      if(type == "del"){
        let dialog = await this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if(dialog != "confirm") return;
      }
      let res = await this.$ZGManager.updateArticle(item.id, params);
      if(res.status == 200){
        this.getArticleList(0);
      }
    },

    // 批量修改文章状态
    async updateMultipleStatus(type){
      let status = "";
      switch(type){
        case 'release': 
          status = "2";
          break;
        case 'dismount':
          status = "0";
          break;
        case 'delete':
          status = "-1";
          break;
        default: status = "";
      }
      let res = await this.$ZGManager.updateArticleStatus(this.multipleSelection, status);
      if(res.status == 200){
        this.getArticleList(0);
      }
    },
    
    // 选中文章后的处理
    handleSelectionChange(val) {
      this.multipleSelection = "";
      val.map(e => {
        this.multipleSelection += `${e.id},`
      });
      this.multipleSelection = this.multipleSelection.substring(0, this.multipleSelection.length - 1);
    },

    // 跳转到编辑文章页面
    retirectPage(item){
      let path;
      if(item){
        path = `#/article/edit?id=${item.id}`
      }else{
        path = "#/article/edit";
      }
      window.open(path);
    },


    // 采集文章
    async pickContent(){
      let dialog = await this.$prompt('请输入采集地址', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      });
      if(dialog.action != 'confirm'){
        return; 
      }
      if(!dialog.value){
        return this.$message.error("您输入的地址有误，请重新输入");
      }
      let res = await this.$ZGManager.pickupArticle(2, 0, dialog.value);
      if(res.status == 200 && res.data){
        this.getArticleList();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  padding: 15px 10px;
}
.header{
  position: relative;
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: bottom;
}
.table-title{
  color: #1a9afd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
</style>